import { Close as CloseIcon } from "@styled-icons/material-outlined/Close";
import FilterBy from "components/Filter/FilterBy";
import { FilterType } from "components/Filter/utils";
import MediaMatch from "components/MediaMatch";
import { useState } from "react";

import SlidersIcon from "./SlidersIcon";
import * as S from "./styles";

type FilterAndSort = {
  items: FilterType[];
};

const FilterAndSort = ({ items }: FilterAndSort) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((isOpen) => !isOpen);
  };

  return (
    <>
      <MediaMatch
        greaterThan="medium"
        style={{ width: "unset" }}
        className="filter-sort_media-match"
      >
        <S.FiltersOptions className="filter-sort">
          {items.map((filter) => (
            <FilterBy
              key={filter.label}
              color="black"
              label={filter.label}
              tags={filter.tags}
              initialTag={filter.initialTag}
            />
          ))}
        </S.FiltersOptions>
      </MediaMatch>
      <MediaMatch
        lessThan="medium"
        style={{ width: "unset" }}
        className="filter-sort_media-match"
      >
        <S.IconWrapper
          onClick={toggleMenu}
          className="filter-sort_icon-wrapper"
        >
          <SlidersIcon className="filter-sort_sliders-icon" />
        </S.IconWrapper>
        <S.MenuFull
          aria-hidden={!isMenuOpen}
          isOpen={isMenuOpen}
          className="filter-sort__mobile-menu"
        >
          <CloseIcon
            aria-label="Close Menu"
            onClick={toggleMenu}
            className="filter-sort_close-menu"
          />
          <S.MenuContent className="filter-sort_menu-content">
            <S.Title className="filter-sort_title">Filter & Sort</S.Title>
            <S.Border className="filter-sort_border" />
            <S.FiltersOptions className="filter-sort_filters-options">
              {items.map((filter) => (
                <FilterBy
                  key={filter.label}
                  label={filter.label}
                  tags={filter.tags}
                  color="black"
                />
              ))}
            </S.FiltersOptions>
          </S.MenuContent>
        </S.MenuFull>
      </MediaMatch>
    </>
  );
};

export default FilterAndSort;
