import * as FilterByStyles from "components/Filter/FilterBy/styles";
import styled, { css } from "styled-components";
import media from "styled-media-query";

export const IconWrapper = styled.button`
  ${({ theme }) => css`
    margin-left: ${theme.spacings.xsmall};
    background-color: transparent;
    color: white;
    cursor: pointer;
    position: relative;
    border-radius: ${theme.border.radius};
    height: 3.2rem;
    width: 6.4rem;
    border: 1px solid #dbdbdb;

    transition: filter ${theme.transition.default};
    &:hover {
      filter: brightness(0.9);
    }

    svg {
      color: ${theme.colors.black};
    }
  `}
`;

type MenuFullProps = {
  isOpen: boolean;
};

export const MenuFull = styled.nav<MenuFullProps>`
  ${({ theme, isOpen }) => css`
    display: flex;
    flex-direction: column;
    color: ${theme.colors.black};
    background: ${theme.colors.white};
    position: fixed;
    z-index: ${theme.layers.menu};
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    overflow: hidden;
    transition: opacity 0.3s ease-in-out;
    opacity: ${isOpen ? 1 : 0};
    pointer-events: ${isOpen ? "all" : "none"};

    a {
      color: ${theme.colors.black};
      text-decoration: none;
    }

    > svg {
      position: absolute;
      top: 0;
      right: 0;
      margin: ${theme.spacings.xsmall};
      cursor: pointer;
      width: 2.4rem;
      height: 2.4rem;
    }

    span {
      transition: transform 0.3s ease-in-out;
      transform: ${isOpen ? "translateY(0)" : "translateY(3rem)"};
    }
  `}
`;

export const MenuContent = styled.div`
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`;

export const FiltersOptions = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-top: 2rem;

    ${FilterByStyles.SelectWrapper} {
      color: black;
      margin-bottom: ${theme.spacings.small};
    }

    ${media.greaterThan("medium")`
      flex-direction: row;
      margin-top: 0;

      ${FilterByStyles.SelectWrapper} {
        margin-bottom: 0;
        margin-left: ${theme.spacings.small};
      }
    `}
  `}
`;

export const Border = styled.div`
  border: 1px solid grey;
`;

export const Title = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xxlarge};
  `}
`;
