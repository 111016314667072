/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Check, ChevronDown, ChevronUp } from "@styled-icons/boxicons-regular";
import MediaMatch from "components/MediaMatch";
import { useRouter } from "next/router";
import { KeyboardEventHandler, useEffect, useRef, useState } from "react";

import { FilterType, labelToTag, TagType } from "../utils";
import * as S from "./styles";

export type FilterTypeColor = "white" | "black";

export type FilterTypeProps = FilterType & {
  color?: FilterTypeColor;
};

const pushOptions = {
  shallow: true,
  scroll: false,
};

const FilterBy = ({
  label,
  tags,
  initialTag,
  color = "white",
}: FilterTypeProps) => {
  const { query = {}, push } = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const iconRef = useRef<SVGSVGElement>(null);
  const valueFromQuery = tags.find((t) => t.value === query[labelToTag[label]]);
  const currentValue = valueFromQuery;
  const [tabIndex, setTabIndex] = useState(-1);

  const liRefs = useRef<HTMLLIElement[]>([]);
  const ulRef = useRef<HTMLUListElement>(null);

  const handleTagChange = (newTag: TagType) => {
    const tagName = labelToTag[label];

    if (!newTag?.value) {
      const { [tagName]: remove, ...rest } = query;
      push({ query: rest }, undefined, pushOptions);
      buttonRef?.current?.focus();
      return;
    }

    push(
      { query: { ...query, [tagName]: newTag.value } },
      undefined,
      pushOptions
    );
    buttonRef?.current?.focus();
  };

  const handleKeyPress: KeyboardEventHandler = (event) => {
    const ENTER = 13;

    if (event.which === ENTER) {
      handleTagChange(tags[tabIndex]);
    }
  };

  const handleUpDownArrows: KeyboardEventHandler = (event) => {
    const KEY_DOWN = 40;
    const KEY_UP = 38;

    if (event.which === KEY_DOWN) {
      event.preventDefault();
      if (tabIndex + 1 < tags.length) {
        setTabIndex((currentTabIndex) => {
          return (currentTabIndex = currentTabIndex + 1);
        });
      }
    }
    if (event.which === KEY_UP) {
      event.preventDefault();
      if (tabIndex > 0) {
        setTabIndex((currentTabIndex) => {
          return (currentTabIndex = currentTabIndex - 1);
        });
      }
    }
  };

  useEffect(() => {
    liRefs.current[tabIndex]?.focus();
  }, [tabIndex]);

  useEffect(() => {
    if (isOpen) {
      ulRef?.current?.focus();
    } else {
      setTabIndex(-1);
    }
  }, [isOpen]);

  useEffect(() => {
    const listener = (evt: MouseEvent) => {
      if (
        evt.target !== buttonRef.current &&
        !buttonRef.current?.contains(evt.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.body.addEventListener("click", listener);

    return () => document.body.removeEventListener("click", listener);
  }, []);

  useEffect(() => {
    if (initialTag) {
      handleTagChange(initialTag);
    }
  }, []);

  return (
    <S.SelectWrapper className="filter-by_wrapper">
      <MediaMatch lessThan="medium" className="filter-by_media-match">
        <S.Label className="filter-by_label">{label}</S.Label>
      </MediaMatch>
      <S.SelectInput
        ref={buttonRef}
        color={color}
        aria-describedby="instructions"
        aria-expanded={isOpen}
        aria-haspopup="true"
        aria-label={`Current ${label} filter: ${currentValue?.label}`}
        onClick={() => setIsOpen(!isOpen)}
        className="filter-by_select-input"
      >
        <MediaMatch
          greaterThan="medium"
          style={{ width: "unset" }}
          className="filter-by_media-match"
        >
          <div aria-hidden="true">{`${label}:`}&nbsp;</div>
        </MediaMatch>
        {currentValue && (
          <strong aria-hidden="true">{currentValue.label}</strong>
        )}
        {isOpen ? (
          <ChevronUp ref={iconRef} size={18} className="filter-by_chevron-up" />
        ) : (
          <ChevronDown size={18} className="filter-by_chevron-down" />
        )}
      </S.SelectInput>
      <S.SelectOptions
        isOpen={isOpen}
        ref={ulRef}
        tabIndex={0}
        aria-label="Select a sale type filter"
        onKeyDown={(event) => handleUpDownArrows(event)}
        className="filter-by_select-options"
      >
        {tags.map((tag, index) => {
          const active = tag === currentValue;
          return (
            <S.SelectOption
              active={active}
              arrowSelected={index === tabIndex}
              aria-selected={active}
              key={index}
              role="option"
              tabIndex={0}
              ref={(el) => (liRefs.current[index] = el as HTMLLIElement)}
              onKeyDown={handleKeyPress}
              onClick={() => handleTagChange(tag)}
              className="filter-by_select-option"
            >
              {tag.label}
              {active && <Check size={18} className="filter-by_check" />}
            </S.SelectOption>
          );
        })}
      </S.SelectOptions>
    </S.SelectWrapper>
  );
};

export default FilterBy;
