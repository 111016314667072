import { ParsedUrlQuery } from "querystring";

export type TagType = {
  label: string;
  value: string | string[] | undefined;
};

export type FilterType = {
  label: string;
  tags: TagType[];
  initialTag?: TagType;
};

export const saleTypeFilter = {
  label: "Sale type",
  tags: [
    { label: "Auction & Buy Now", value: undefined },
    { label: "Auction", value: "auction" },
    { label: "Buy Now", value: "buy_now" },
  ],
};

export const sortByFilter = {
  label: "Sort By",
  tags: [
    { label: "Most Recent", value: undefined },
    { label: "Ending Soon", value: "ending_soon" },
    { label: "Price (high to low)", value: "price_highest_first" },
    { label: "Price (low to high)", value: "price_lowest_first" },
  ],
};
export const sortByFilterPack = {
  label: "Sort By",
  tags: [],
};

export const queryToTags = (query: ParsedUrlQuery) => {
  if (!query) {
    return [];
  }

  return Object.entries(query)
    .map(([key, value]) => {
      if (!value) return {};
      if (Array.isArray(value)) return {};

      return {
        label: tagToLabel[key],
        value: tagToLabel[value],
      };
    })
    .filter((item) => item.label && item.value);
};

type ObjectType = {
  [key: string]: string;
};

export const labelToTag: ObjectType = {
  "Search Term": "searchTerm",
  "Sale type": "saletype",
  "Price Range": "pricerange",
  "Sort By": "sortby",
  ...saleTypeFilter.tags.reduce((acc, curr) => {
    acc[curr.label] = curr.value;
    return acc;
  }, {}),
  ...sortByFilter.tags.reduce((acc, curr) => {
    acc[curr.label] = curr.value;
    return acc;
  }, {}),
};

export const tagToLabel = Object.entries(labelToTag).reduce(
  (acc, [key, value]) => {
    acc[value] = key;
    return acc;
  },
  {} as ObjectType
);

export const allFilters: FilterType[] = [saleTypeFilter, sortByFilter];
