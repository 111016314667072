import styled, { css } from "styled-components";
import media from "styled-media-query";

import { FilterTypeProps } from ".";

export const Select = styled.select<Pick<FilterTypeProps, "color">>`
  ${({ theme, color }) => css`
    background-color: transparent;
    border: none;
    outline: none;
    color: ${theme.colors[color!]};
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: ${theme.font.bold};
    font-size: ${theme.font.sizes.small};

    option {
      background-color: transparent;
    }
  `}
`;

interface SelectProps {
  isOpen: boolean;
  color?: string;
}

export const SelectWrapper = styled.div`
  position: relative;
  width: fit-content;

  ${media.greaterThan("medium")`
    margin-left: ${({ theme }) => theme.spacings.xsmall};
  `}
`;

export const SelectInput = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-transform: capitalize;
  background-color: transparent;
  border: none;
  cursor: pointer;
  border: 1px solid #dbdbdb;
  svg {
    pointer-events: none;
  }

  ${({ theme, color = theme.colors.black }) => css`
    border-radius: ${theme.border.radius};
    padding: ${theme.spacings.xxsmall} ${theme.spacings.xsmall};
    color: ${color};
  `}
`;

export const SelectOptions = styled.ul<SelectProps>`
  ${({ isOpen, theme }) => css`
    color: ${theme.colors.black};
    position: absolute;
    display: ${isOpen ? "block" : "none"};
    background: ${theme.colors.white};
    cursor: pointer;
    border: 1px solid ${theme.colors.lightGray};
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04),
      0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: ${theme.border.radius};
    padding: ${theme.spacings.xsmall} ${theme.spacings.small};
    z-index: ${theme.layers.alwaysOnTop};
    right: 0;
    top: calc(100% + ${theme.spacings.xxsmall});
    white-space: nowrap;

    ${SelectOption}:not(:last-child) {
      margin-bottom: ${theme.spacings.xxsmall};
    }
    ${media.lessThan("medium")`
      left: 0;
      right: unset;
    `}
  `}
`;

interface SelectOptionProps {
  active?: boolean;
  arrowSelected?: boolean;
}

export const SelectOption = styled.li<SelectOptionProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ active, arrowSelected, theme }) => css`
    font-size: ${theme.font.sizes.small};
    line-height: ${theme.spacings.small};
    &:hover {
      color: ${theme.colors.red};
    }
    color: ${active || arrowSelected ? theme.colors.red : theme.colors.black};
    font-weight: ${active ? 500 : theme.font.normal};
    svg {
      margin-left: ${theme.spacings.xxsmall};
    }
  `}
`;

export const Label = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.small};
    margin-bottom: ${theme.spacings.xxsmall};
    font-weight: ${theme.font.light};
  `}
`;
