import * as React from "react";

function SlidersIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 6.75v6.75m0-11v1.75m0 2.5a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zm4.5 5v1.75m0-11v6.75m0 2.5a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zm-9-2v3.75m0-11v4.75m0 2.5a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
        stroke="#040707"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SlidersIcon;
